@tailwind base;
@tailwind components;
@tailwind utilities;
  

.dropdown-enter {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .dropdown-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1000ms, transform 2000ms;
  }
  
  .dropdown-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .dropdown-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 1000ms, transform 2000ms;
  }

  .dropdown-menu {
    border: 1px solid black;
  }
  
  .font-Anton {
    font-family: 'Anton', sans-serif;
  }

  .font-Antonio {
    font-family: 'Antonio', sans-serif;
  }

  button {
    font-family: 'Anton', sans-serif;
  }

  @keyframes slideIn {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .card-mobile {
    max-width: 300px; /* Adjust this value as needed */
    margin: 0 auto; /* This will center your card if the screen is wider than 300px */
  }
  
  